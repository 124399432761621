.editArticleContainer {
  width: 831px;
  margin-top: 40px;
}
.addCoverPhotoContainer {
  cursor: pointer;
}
.uploadedImageParentContainer {
  margin: 40px 0;
}
.uploadedImage {
  max-width: 400px;
  width: 100%;
  margin-bottom: 20px;
  border-radius: 6px;
}
.imageRemoveButton {
  background-color: red;
  color: white;
  font-family: "Poppins", sans-serif;
  text-transform: capitalize;
  width: 200px;
  padding: 10px 0;
  border: none;
  outline: none !important;
  border-radius: 50px;
}
.addCoverPhoto {
  margin: auto 7px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 167.9%;
  color: rgba(56, 56, 56, 0.46);
}
.articleHeaderEditorContainer {
  margin: 20px 0 35px;
}
.articleHeaderEditor {
  height: 65px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  width: 100%;
  border-color: var(--lightGray);
  outline: none !important;
  border: 1px solid #aeadad;
  border-radius: 6px;
  padding: 0 27px;
}
.articleHeaderEditor::-webkit-input-placeholder {
  /* Edge */
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  color: rgba(56, 56, 56, 0.51);
  text-transform: capitalize;
}

.articleHeaderEditor:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  color: rgba(56, 56, 56, 0.51);
  text-transform: capitalize;
}

.articleHeaderEditor::placeholder {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  color: rgba(56, 56, 56, 0.51);
  text-transform: capitalize;
}
.categorySelectorContainer {
  margin-bottom: 43px;
  width: 281px;
}
.chooseCategorySelector {
  background: #ffffff;
  border: 1px solid #aeadad;
  border-radius: 6px;
  width: 281px;
  height: 52px;
  padding: 0 27px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 148.9%;
  color: var(--lightGray);
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  margin-bottom: 43px;
  width: 281px;
}
.dropDownArrow {
  position: absolute;
  font-size: 30px;
  display: block;
  position: absolute;
  top: 12px;
  right: 20px;
}
@media (max-width: 1399px) {
  .editArticleContainer {
    width: 100%;
  }
}
@media (max-width: 575px) {
  .articleHeaderEditor {
    font-size: 30px;
  }
}
