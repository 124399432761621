.listItem {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 148.9%;
  color: #000000;
  text-transform: capitalize;
  margin: auto;
  transition: all 0.1s;
}
.listItem:hover {
  color: var(--lightGray);
}
@media (max-width: 1399px) {
  .listItem {
    font-size: 17px;
  }
}
@media (max-width: 1199px) {
  .listItem {
    font-size: 16px;
  }
}
