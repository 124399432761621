.listTitle {
  font-size: 17px;
  margin-bottom: 30px;
  color: var(--lightGray);
}
.listContainer {
  margin-bottom: 70px;
}
@media (max-width: 575px) {
  .listTitle {
    font-size: 16px;
  }
}
