.primaryButton {
  border-radius: 60px;
  padding: 24px 49px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  text-transform: capitalize;
  background-color: var(--primaryColor1);
  color: #171e26;
  border: none;
}
