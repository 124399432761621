.customModalBody {
  padding: 0;
}
.modalCloseButtonContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 28px;
  padding-right: 25px;
  margin-bottom: 9px;
}
.modalCloseButton {
  outline: none !important;
  box-shadow: none !important;
  color: var(--lightGray);
  font-weight: lighter;
  cursor: pointer;
}
.modalBodyContainer {
  padding: 0 65px;
}
.modalTitle {
  font-weight: 500;
  font-size: 30px;
  line-height: 106.9%;
  color: #000000;
  margin-bottom: 17px;
}
.modalTopText {
  font-weight: 400;
  font-size: 19px;
  line-height: 167.9%;
  color: var(--lightGray);
  margin-bottom: 28px;
}
.fewSuggestionsBox {
  box-sizing: border-box;
  width: 100%;
  background: var(--lightBlue);
  border-left: 7px solid var(--primaryColor2);
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 19px;
  margin-bottom: 48px;
}
.fewSuggestionsText {
  font-style: italic;
  font-weight: 500;
  font-size: 18px;
  line-height: 106.9%;
  color: var(--secondaryColor1);
  margin: 0;
  padding-left: 40px;
}
.carouselParentContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 49px;
}
.carouselContainer {
  width: 90%;
}
.seperateEmailsText {
  font-style: italic;
  font-weight: 300;
  font-size: 18px;
  line-height: 167.9%;
  color: var(--secondaryColor1);
  margin-bottom: 6px;
}
.emailInputField {
  width: 100%;
  border: 1px solid var(--secondaryColor4);
  padding: 17px 24px;
  outline: none !important;
}
@media (max-width: 460px) {
  .modalBodyContainer {
    padding: 0 25px;
  }
}
@media screen and (max-width: 400px) {
  .emailsAdded {
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.emailsAdded {
  font-weight: 400;
  font-size: 16px;
  line-height: 170.9%;
  color: #000000;
  margin: 0;
  padding-right: 5px;
}
.btnDiv {
  width: 100%;
  margin-top: 24px;
  display: flex;
  align-content: center;
  justify-content: center;
}
.inviteBtn {
  height: 61px;
  width: 163px;
  background: var(--primaryColor1);
  border: none;
  border-radius: 60px;
  margin-bottom: 59px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.inviteBtnText {
  font-family: "Baloo Da 2";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 148.9%;
  text-align: center;
  color: #000000;
  text-transform: capitalize;
  margin: 0;
}
.errorMessage {
  color: red;
  font-family: Poppins;
  font-size: 13px;
  margin: 0;
}
