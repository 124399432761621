.Description {
  color: var(--secondaryColor1);
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 19px;
  line-height: 164.9%; /* 31.331px */
}
.Description .clickHere {
  color: var(--primaryColor2);
  text-decoration: underline;
}
.Description .warningMessage {
  color: var(--secondaryColor2);
}
.DescriptionInfoIcon {
  position: relative;
  top: 6px;
  font-size: 25px;
  margin-right: 1px;
  color: var(--primaryColor1);
}
.descriptionUnderLine {
  width: 100%;
  height: 1px;
  border-top: 1px solid rgba(56, 56, 56, 0.16);
  margin-bottom: 61px;
}
