.signUpModalTextInputContainer {
  margin-bottom: 19px;
}
.signUpModalTextInput {
  width: 100%;
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #aeadad;
  border-radius: 5px;
  padding: 12.5px 22px;
  outline: none !important;
}
.signUpModalInputLabel {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 148.9%;
  color: var(--lightGray);
  margin-bottom: 5px;
  text-transform: capitalize;
}
.errorMessage {
  color: red;
  font-family: "Poppins", sans-serif;
  font-size: 13px;
}
@media (max-width: 575px) {
  .signUpModalTextInput {
    margin-bottom: 9px;
  }
}
