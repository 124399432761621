.inputSelectTextAreaContainer {
  margin-bottom: 27px;
}
.inputSelectTextArea {
  box-sizing: border-box;
  width: 100%;
  background: #ffffff;
  border: 1px solid #aeadad;
  border-radius: 5px;
  outline: none;
  padding: 17px 23px;
}
.errorMessage {
  color: red;
  font-family: "Poppins", sans-serif;
  font-size: 13px;
}
