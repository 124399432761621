.inputContainerParent {
  margin-bottom: 31px;
}
.aboutInputContainer {
  border: 1px solid var(--secondaryColor4);
  width: 100%;
  padding: 12px 23px;
  outline: none !important;
  border-radius: 12px;
}
.errorMessage {
  color: red;
  font-family: "Poppins", sans-serif;
  font-size: 13px;
}
