.numberContainer {
  width: 49px;
  height: 49px;
  border-radius: 50%;
  background: var(--secondaryColor3);
  display: flex;
  color: white;
  /* margin-right: 22px; */
}
.contentContainer {
  font-style: italic;
  font-weight: 400;
  font-size: 22px;
  line-height: 148.9%;
  color: #383838;
  margin: auto;
}
.flowItemContainer {
  display: flex;
  margin-bottom: 18px;
}
