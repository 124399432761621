.articleContainer {
  margin-bottom: 45px;
}
.articleImage {
  width: 100%;
  height: 217px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin-bottom: 16px;
}
.articleTitle {
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 148.9%;
  color: #000000;
  margin-bottom: 21px;
}
.articleContent {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 148.9%;
  color: var(--secondaryColor3);
}
.articleAuthor {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 148.9%;
  text-transform: uppercase;
  color: var(--secondaryColor3);
  margin: 0;
}
