.writecommentSectionTitle {
  font-weight: 600;
  font-size: 23px;
  line-height: 106.9%;
  color: var(--secondaryColor1);
}
.newCommentInput {
  background: #f5f5f5;
  border: none;
  outline: none !important;
  width: 100%;
  padding: 15px 20px;
  margin-bottom: 23px;
}
.commentSubmitButton {
  width: 188px;
  height: 63px;
  background: var(--primaryColor1);
  border-radius: 60px;
  border: none;
  outline: none !important;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  color: #000000;
  margin-bottom: 23px;
}
.errorMessage {
  color: red;
  font-family: "Poppins", sans-serif;
  font-size: 13px;
}
@media (max-width: 575px) {
  .commentSubmitButton {
    width: 100%;
    margin-bottom: 70px;
  }
}
